<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">后台管理系统</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="user_login">
                    <el-input v-model="param.user_login" placeholder="请输入用户名">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="user_pass">
                    <el-input
                        type="password"
                        placeholder="请输入密码"
                        v-model="param.user_pass"
                        @keyup.enter="submitForm()"
                    >
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import { Login } from "../api/index";
// import { useRouter } from "vue-router";
// import { reactive } from "vue";
export default {
    data() {
        return {
            param: {
                user_login: this.user_login,
                user_pass: this.user_pass
            },
            rules: {
                user_login: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                user_pass: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.$store.commit("clearTags");
    },
    methods: {
        
        // 获取 easy-mock 的模拟数据
        submitForm() {
            this.$refs.login.validate(valid => {
                if (valid) {
                    Login(this.param).then(res => {
                        console.log(res);
                        if(res.code == 1){
                            this.$message.success("登录成功");
                            localStorage.setItem("ms_username", this.param.user_login);
                            localStorage.setItem("token", res.data.token);
                            localStorage.setItem("itemRouter",JSON.stringify(res.data.items))

                            // 获取路由器实例
                            // const router = useRouter();
                            // const menuData = reactive({
                            //     openKeys: [],
                            //     menu: [],
                            //     collapsed: false
                            // });
                            // menuData.menu = res.data.route;

                            // routerPackag(res.data.route);
                            
                            // // 路由数据重新封装
                            // const routerPackag = routers => {
                            //     routers.filter(itemRouter => {
                            //         console.log(itemRouter);
                            //         if (itemRouter.component != "views") {
                            //             router.addRoute("Sidebar", {
                            //                 path: `${itemRouter.path}`,
                            //                 name: itemRouter.name,
                            //                 component: () => import(`@/${itemRouter.component}`)
                            //             });
                            //         }
                            //         // 是否存在子集
                            //         if (itemRouter.children && itemRouter.children.length) {
                            //             routerPackag(itemRouter.children);
                            //         }
                            //         return true;
                            //     });
                            // };

                            localStorage.setItem("routers",JSON.stringify(res.data.route))

                            // var roterArr = res.data.route
                            // for (const key in roterArr) {
                            //     if (Object.hasOwnProperty.call(roterArr, key)) {
                            //         const element = roterArr[key];
                            //         // element.component = () => import(`@/${roterArr[key]}`)
                            //         console.log( element);
                            //     }
                            // }
                            this.$router.push("/");
                        }else{
                            this.$message.error(res.msg)
                        }
                    });
                } else {
                    this.$message.error("请输入账号和密码");
                    return false;
                }
            });
        }
    },
    setup(){
    },
};
</script>

<style scoped>
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/login-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #000000;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>